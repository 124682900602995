<app-header></app-header>

<div class="home-page-container">
  <section class="introduction-container">
    <section class="caption-container">
      <h1>
        Rooted in <br />
        Tomorrow.
      </h1>
    </section>
    <section class="title-container">
      <hr />
      <p>
        Spruce: innovating easy access <br />
        to sustainable energy for a brighter tomorrow
      </p>
    </section>
    <section class="scroll-container ">
      <a (click)="scrollToSection()" class="show-cursor">
        <div class="down-arrow">
          <i class="fa-solid fa-angle-down"></i>
        </div>
      </a>
    </section>
  </section>
  <br />
  <br />
  <section class="about-container container" id="main-content">
    <div class="row">
      <div class="col-12 col-md-12 col-lg-6">
        <div class="focus-details-container">
          <div class="spruce-focus-container">
            <h1 class="home-heading">At Spruce, we focus on clean energy solutions</h1>
            <p>
              Spruce Power is a leading owner and operator of distributed solar
              energy assets across the United States. By offering subscription-based
              services to over 52,000 customers, Spruce is able to make renewable
              energy more accessible to everyone.
            </p>
            <p>
              Working together with our customers and partners, we relentlessly
              pursue opportunities to create a cleaner and more sustainable future.
              We lead the way by creating accessible and reliable solutions to
              deliver on our promise to harness the power of nature.
            </p>
          </div>
          <div class="numbers-container row mt-5">
            <div class="col-12 col-lg-6 ps-0">
              <div class="left-numbers-container">
                <div class="left-number row">
                  <div class="col-3 number">
                    <h3>15</h3>
                  </div>
                  <div class="col-9 description">year track record in clean energy.</div>
                </div>
                <div class="left-number row">
                  <div class="col-3 number">
                    <h3>80<span class="number-length">k</span></h3>
                  </div>
                  <div class="col-9 description">
                    solar systems and contracts serviced.
                  </div>
                </div>
                <div class="left-number row">
                  <div class="col-3 number">
                    <h3>18</h3>
                  </div>
                  <div class="col-9 description">states with active customers.</div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 ps-0">
              <div class="right-numbers-container">
                <div class="right-number row">
                  <div class="number col-3 col-lg-4">
                    <h3>$1<span class="number-length">B</span></h3>
                  </div>
                  <div class="col-9 col-lg-8 description">residential solar assets serviced.</div>
                </div>
                <div class="right-number row">
                  <div class="number col-3 col-lg-4">
                    <h3>2.2<span class="number-length">b</span></h3>
                  </div>
                  <div class="col-9 col-lg-8 description">
                    kWh of lifetime renewable energy generated, avoiding 1.6 million
                    tons of carbon dioxide equivalent emissions.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="promise-container mt-3">
            <strong>Our promise.</strong>
            <p>
              We provide flexible and reliable products, helping homeowners take
              control of their power needs with affordable home energy solutions.
            </p>
            <a [routerLink]="['/about']">Learn More</a>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-12 col-lg-6">

        <div class="about-image">
          <img src="assets/rainforest.jpg" alt="rainforestImage" />
        </div>
      </div>
    </div>

  </section>
  <section class="services-container">
    <div class="spruce-pro-header container">
      <h1>Spruce Pro</h1>
      <hr />
      <h3 class="mt-2">Your Premier Full-Scope Managed Services Provider</h3>
      <p class="experience-message">
        <i>Our Experience Equals Your Benefit</i>
      </p>
    </div>
    <div class="details-container mt-4 container">
      <div class="learn-more">
        <div class="card top-card">
          <div class="card-body">
            <span class="learn-more-heading">Spruce Pro</span>
            <a [routerLink]="['/pro']" class="btn btn-link"
              ><span class="link"
                >Learn More <span class="arrow">&#8594;</span></span
              ></a
            >
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <span class="learn-more-heading">Spruce Servicing</span>
            <a [routerLink]="['/spruce-servicing']" class="btn btn-link"
              ><span class="link"
                >Learn More <span class="arrow">&#8594;</span></span
              ></a
            >
          </div>
        </div>
      </div>
      <div class="solar-installation">
        <img
          src="assets/Commercial-Solar-Installation.jpg"
          alt="Solar Installation Image"
        />
      </div>
    </div>
  </section>
  <section class="questions-help-container pt-5 pb-5">
    <h1>Other questions or help?</h1>
    <button type="button" class="btn" [routerLink]="['/get-in-touch']">
      Contact Us
    </button>
    <button type="button" class="btn" [routerLink]="['/faq']">FAQs</button>
  </section>
</div>
<app-footer></app-footer>
